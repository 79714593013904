import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Stack,
  TablePagination,
  Typography,
  CircularProgress,
  Chip
} from '@mui/material';
import Layout from 'src/components/Layout';
import { DataGrid } from '@mui/x-data-grid';
import { getCollectionList } from 'src/api';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/utils/AuthWrapper';
import { toast } from 'react-toastify';

const History = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCollectionList();
  }, [page, limit, getCollectionList]);

  const fetchCollectionList = () => {
    setLoading(true);
    getCollectionList(limit, page + 1)
      .then((res) => {
        if (res?.response) {
          if (res.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('credit');
            logout();
            navigate('/login');
            toast.error("You've been logged out. Please log in again.");
            return;
          }
        }
        if (res?.data?.status) {
          setData(res?.data?.data);
          setTotalCount(res?.data?.meta?.total_count);
          setLoading(false);
        }
      })
      .catch((e) => console.log('======>element', e));
  };

  const StatusPill = ({ status }) => {
    let label;
    let color;
    switch (status) {
      case 'in_progress':
        label = 'In Progress';
        color = 'primary';
        break;
      case 'succeed':
        label = 'Success';
        color = 'success';
        break;
      case 'failed':
        label = 'Failed';
        color = 'error';
        break;
      default:
        label = status;
        color = 'default';
    }

    return <Chip label={label} size="small" color={color} variant="outlined" />;
  };

  const columns = [
    {
      field: 'collection_name',
      headerName: 'Collection Name',
      flex: 1,
      renderHeader: (params) => (
        <Typography variant="h4">{params.colDef.headerName}</Typography>
      ),
      renderCell: (params) => {
        return (
          <>
            {params.row.status === 'succeed' ? (
              <Link
                style={{ textDecoration: 'none' }}
                to={`/history/${params?.row?.collection_id}`}
              >
                <Typography color="#000" variant="body1">
                  {params.row.collection_name}
                </Typography>
              </Link>
            ) : (
              <Typography color="#000" variant="body1">
                {params.row.collection_name}
              </Typography>
            )}
          </>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderHeader: (params) => (
        <Typography variant="h4">{params.colDef.headerName}</Typography>
      ),
      renderCell: (params) => <StatusPill status={params.row.status} />
    }
  ];

  const handlePageChange = (_event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  // const getRowId = (row) => row.id;

  // const getRowClassName = (params) => {
  //   return params?.row?.collection_id ? 'collection-row' : '';
  // };

  return (
    <Layout>
      <Stack
        sx={{
          p: 2,
          margin: 'auto',
          display: 'flex',
          pt: 12,
          flexDirection: 'column',
          alignItems: 'center'
        }}
        spacing={4}
      >
        <Typography variant="h2" textTransform="uppercase">
          History
        </Typography>
      </Stack>
      <Container>
        {loading ? (
          <CircularProgress sx={{ display: 'flex', mt: 2, margin: '0 auto' }} />
        ) : (
          <Paper sx={{ backgroundColor: '#ffffffa1' }}>
            {data?.length >= 1 ? (
              <>
                <DataGrid
                  rows={data?.map((user, index) => ({
                    ...user,
                    id: index.toString()
                  }))}
                  columns={columns}
                  pageSize={limit}
                  page={page}
                  hideFooter
                  onPageChange={(newPage) => handlePageChange(null, newPage)}
                  disableRowSelectionOnClick
                />
                <TablePagination
                  component="div"
                  count={totalCount}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </>
            ) : (
              <Typography textAlign="center" variant="h4" p={4}>
                No data found
              </Typography>
            )}
          </Paper>
        )}
      </Container>
    </Layout>
  );
};

export default History;
