import { createTheme } from '@mui/material';

const themeColors = {
  dark: '#000'
};

export const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textTransform: 'uppercase',
          paddingLeft: 16,
          paddingRight: 16,
          backgroundColor: '#0a325061',
          color: '#000',
          borderRadius: 20,
          '&:hover, &.MuiSelected': {
            backgroundColor: '#0a325061',
            color: '#000'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '50px !important',
          paddingLeft: '20px !important'
        }
      }
    }
  },
  typography: {
    fontFamily: '"Geometria (Medium)", "Capital letters", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: 35
    },
    h2: {
      fontWeight: 700,
      fontSize: 30
    },
    h3: {
      fontWeight: 700,
      fontSize: 25,
      lineHeight: 1.4,
      color: themeColors.dark
    },
    h4: {
      fontWeight: 700,
      fontSize: 16
    },
    h5: {
      fontWeight: 700,
      fontSize: 14
    },
    h6: {
      fontSize: 15
    },
    body1: {
      fontSize: 14
    },
    body2: {
      fontSize: 14
    },
    button: {
      fontWeight: 600
    },
    caption: {
      fontSize: 13,
      color: themeColors.dark
    },
    subtitle1: {
      fontSize: 14,
      color: themeColors.dark
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 15,
      color: themeColors.dark
    },
    overline: {
      fontSize: 13,
      fontWeight: 700
    }
  }
});
