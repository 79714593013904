import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
// import Dashboard from './pages/Dashboard';
import About from './pages/About';
import AddData from './pages/AddData';
import History from './pages/History';
import { useAuth } from './utils/AuthWrapper';
import NotFound from './components/NotFound';
import CampaignsList from './pages/History/View';
import CampaignStatistics from './pages/History/View/CampaignStatistics';
import Plans from './pages/Plans';
import Success from './pages/Plans/Success';
import Failed from './pages/Plans/Failed';
import { useEffect, useState } from 'react';

export const TpLoader = () => {
  return (
    <div className="tp-loader-wrapper">
      <div className="tp-spinner" />
    </div>
  );
};
function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { isLoggedIn } = useAuth();
  const token = localStorage.getItem('token');

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {isLoading && <TpLoader />}
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/login"
          element={
            isLoggedIn || token ? <Navigate to="/add-data" /> : <Login />
          }
        />
        <Route
          path="/register"
          element={
            isLoggedIn || token ? <Navigate to="/add-data" /> : <Register />
          }
        />

        <Route
          path="/about"
          element={isLoggedIn || token ? <About /> : <Navigate to="/login" />}
        />
        <Route
          path="/add-data"
          element={isLoggedIn || token ? <AddData /> : <Navigate to="/login" />}
        />

        <Route
          path="/history"
          element={isLoggedIn || token ? <History /> : <Navigate to="/login" />}
        />
        <Route
          path="/history/:id"
          element={
            isLoggedIn || token ? <CampaignsList /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/history/:id/:campaignId"
          element={
            isLoggedIn || token ? (
              <CampaignStatistics />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/plans"
          element={isLoggedIn || token ? <Plans /> : <Navigate to="/login" />}
        />
        <Route
          path="/success"
          element={isLoggedIn || token ? <Success /> : <Navigate to="/login" />}
        />
        <Route
          path="/failed"
          element={isLoggedIn || token ? <Failed /> : <Navigate to="/login" />}
        />
      </Routes>
    </>
  );
}

export default App;
