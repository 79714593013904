import React, { useState } from 'react';
import Layout from 'src/components/Layout';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { signUpApi } from 'src/api';
import { toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Register = () => {
  const [message, setMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isTabletScreen = useMediaQuery('(max-width:1024px)');
  const navigate = useNavigate();

  return (
    <Layout>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          mx: isTabletScreen && 2
        }}
      >
        <Box sx={{ width: 500, maxWidth: '100%' }}>
          <Typography
            variant="h3"
            textAlign="center"
            textTransform="uppercase"
            mb={5}
          >
            Sign Up
          </Typography>
          <Formik
            initialValues={{
              email: '',
              name: '',
              password: '',
              confirmPassword: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().trim().required('Required'),
              name: Yup.string().trim().required('Required'),
              password: Yup.string().trim().required('Required'),
              confirmPassword: Yup.string()
                .required('Required')
                .oneOf(
                  [Yup.ref('password'), null],
                  'Passwords and confirm password should be same'
                )
            })}
            onSubmit={async (
              _values,
              { resetForm, setStatus, setSubmitting, setErrors }
            ) => {
              let obj = {
                email: _values.email,
                name: _values.name,
                password: _values.password
              };
              await signUpApi(obj)
                .then((res) => {
                  if (res) {
                    if (res?.response?.data?.email?.length > 0) {
                      setMessage(res?.response?.data?.email[0]);
                    } else {
                      navigate('/login');
                      toast.success(res?.data?.msg);
                      resetForm();
                      setStatus({ success: true });
                      setSubmitting(false);
                    }
                  }
                })
                .catch((err) => {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  name="email"
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  label={'Email'}
                  placeholder={'Email'}
                  sx={{ mt: 2 }}
                  autoComplete="off"
                />
                <TextField
                  fullWidth
                  name="name"
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  label={'Name'}
                  placeholder={'Name'}
                  sx={{ mt: 2 }}
                  autoComplete="off"
                />
                <TextField
                  fullWidth
                  name="password"
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  label={'Password'}
                  placeholder={'Password'}
                  autoComplete="off"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  sx={{ mt: 2, borderRadius: 50 }}
                />
                <TextField
                  fullWidth
                  name="confirmPassword"
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  value={values.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  label={'Confirm Password'}
                  placeholder={'Confirm Password'}
                  autoComplete="off"
                  type={showConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  sx={{ mt: 2, borderRadius: 50 }}
                />
                {message && <Typography color="error">{message}</Typography>}
                <Stack mt={7} direction="row" justifyContent="center">
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? <CircularProgress size="1rem" /> : null
                    }
                    sx={{ width: '40%', py: 1 }}
                  >
                    Register
                  </Button>
                </Stack>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Layout>
  );
};

export default Register;
