import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, FieldArray } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import Layout from 'src/components/Layout';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { addCollectionApi } from 'src/api';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
// import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { useAuth } from 'src/utils/AuthWrapper';

const AddData = () => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const credit = localStorage.getItem('credit');
  const isTablatView = useMediaQuery('(max-width:900px)');
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();

  return (
    <Layout>
      <Stack
        sx={{
          p: 2,
          margin: 'auto',
          display: 'flex',
          pt: 12,
          flexDirection: 'column',
          alignItems: 'center'
        }}
        spacing={4}
      >
        <Typography variant="h2" textTransform="uppercase">
          Add data
        </Typography>

        <Formik
          initialValues={{
            dateRanges: [{ startDate: null, endDate: null, campaign_name: '' }],
            file: null,
            collection_name: ''
          }}
          validationSchema={Yup.object().shape({
            collection_name: Yup.string().required('Required'),
            dateRanges: Yup.array().of(
              Yup.object().shape({
                campaign_name: Yup.string().required('Required'),
                startDate: Yup.date()
                  .nullable()
                  .required('Required')
                  .default(null),
                endDate: Yup.date()
                  .nullable()
                  .required('Required')
                  .default(null)
                  .test(
                    'is-valid-date',
                    'Please select end date startdate',
                    function (endDate, context) {
                      const startDate = context.parent.startDate;
                      if (!startDate || !endDate) {
                        return true;
                      }
                      return moment(endDate).isSameOrAfter(startDate);
                    }
                  )
              })
            ),
            file: Yup.mixed()
              .required('File is required')
              .test(
                'file-type',
                'Invalid file format. Please upload a CSV or Excel file.',
                (value) => {
                  if (!value) return true;

                  const acceptedFormats = [
                    'application/vnd.ms-excel',
                    'text/csv',
                    '.csv'
                  ];

                  return acceptedFormats.includes(value.type);
                }
              )
          })}
          onSubmit={async (_values, { resetForm }) => {
            setLoading(true);
            let temp = [];
            _values.dateRanges.forEach((ele) => {
              if (ele) {
                temp.push({
                  campaign_name: ele.campaign_name,
                  start_date: moment(ele.startDate).format(
                    'YYYY-MM-DDTHH:mm:ss[Z]'
                  ),
                  end_date: moment(ele.endDate).format('YYYY-MM-DDTHH:mm:ss[Z]')
                });
              }
            });
            const formData = new FormData();
            formData.append('collection_name', _values.collection_name);
            formData.append('campaigns', JSON.stringify(temp));
            formData.append('file', _values.file);

            addCollectionApi(token, formData)
              .then((res) => {
                if (res?.response) {
                  if (res.response.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('userId');
                    localStorage.removeItem('credit');
                    logout();
                    navigate('/login');
                    toast.error("You've been logged out. Please log in again.");
                    return;
                  }
                }
                if (res.status) {
                  setLoading(false);
                  navigate('/history');
                  toast.success(res?.data?.msg);
                  resetForm({
                    values: {
                      dateRanges: [
                        { startDate: null, endDate: null, campaign_name: '' }
                      ],
                      file: null,
                      collection_name: ''
                    }
                  });
                  document.getElementById('file-input-label').innerText =
                    'Please, add additional data in .CSV/ .EXCEL formats';
                } else {
                  toast.error('Data Not Added');
                }
              })
              .catch((e) => console.log(e));
          }}
        >
          {({
            errors,
            handleSubmit,
            isSubmitting,
            values,
            setFieldValue,
            handleBlur,
            touched
          }) => (
            <form onSubmit={handleSubmit}>
              <Container>
                <Box>
                  <TextField
                    sx={{ width: isTablatView ? '100%' : '50%' }}
                    name="collection_name"
                    placeholder="Collection Name"
                    label="Collection Name"
                    helperText={
                      touched.collection_name && errors.collection_name
                    }
                    error={Boolean(
                      touched.collection_name && errors.collection_name
                    )}
                    value={values.collection_name}
                    autoComplete="off"
                    onChange={(e) =>
                      setFieldValue(`collection_name`, e.target.value)
                    }
                    onBlur={handleBlur}
                  />
                  <FieldArray
                    name="dateRanges"
                    render={(arrayHelpers) => (
                      <Stack
                        sx={{ width: '100%' }}
                        direction="row"
                        alignItems="center"
                        mt={3}
                      >
                        <Box sx={{ width: isTablatView ? '100%' : undefined }}>
                          {values.dateRanges.map((_, index) => (
                            <Stack
                              sx={{
                                flexDirection: isTablatView ? 'column' : 'row',
                                gap: isTablatView && '10px',
                                alignItems: 'center'
                              }}
                              key={index}
                              direction="row"
                              alignItems="center"
                              spacing={!isTablatView && 4}
                              mt={1}
                            >
                              <TextField
                                sx={{
                                  width: isTablatView ? '100%' : undefined
                                }}
                                name={`dateRanges[${index}].campaign_name`}
                                placeholder="Campaigns Name"
                                label="Campaigns Name"
                                autoComplete="off"
                                onChange={(e) =>
                                  setFieldValue(
                                    `dateRanges[${index}].campaign_name`,
                                    e.target.value
                                  )
                                }
                                onBlur={handleBlur}
                                helperText={
                                  touched.dateRanges?.[index]?.campaign_name &&
                                  errors.dateRanges?.[index]?.campaign_name
                                }
                                error={Boolean(
                                  touched.dateRanges?.[index]?.campaign_name &&
                                    errors.dateRanges?.[index]?.campaign_name
                                )}
                                value={values.dateRanges[index].campaign_name}
                              />

                              <DatePicker
                                sx={{
                                  width: isTablatView ? '100%' : undefined
                                }}
                                name={`dateRanges[${index}].startDate`}
                                value={values.dateRanges[index].startDate}
                                onChange={(newValue) => {
                                  setFieldValue(
                                    `dateRanges[${index}].startDate`,
                                    newValue.$d
                                  );
                                }}
                                // viewRenderers={{
                                //   hours: renderTimeViewClock,
                                //   minutes: renderTimeViewClock,
                                //   seconds: renderTimeViewClock
                                // }}
                                slotProps={{
                                  textField: {
                                    onBlur: handleBlur,
                                    helperText:
                                      touched.dateRanges?.[index]?.startDate &&
                                      errors.dateRanges?.[index]?.startDate,
                                    error: Boolean(
                                      touched.dateRanges?.[index]?.startDate &&
                                        errors.dateRanges?.[index]?.startDate
                                    )
                                  }
                                }}
                                inputFormat="dd/MM/yyyy"
                              />
                              <DatePicker
                                sx={{
                                  width: isTablatView ? '100%' : undefined
                                }}
                                name={`dateRanges[${index}].endDate`}
                                value={values.dateRanges[index].endDate}
                                onChange={(newValue) => {
                                  setFieldValue(
                                    `dateRanges[${index}].endDate`,
                                    newValue.$d
                                  );
                                }}
                                // viewRenderers={{
                                //   hours: renderTimeViewClock,
                                //   minutes: renderTimeViewClock,
                                //   seconds: renderTimeViewClock
                                // }}
                                slotProps={{
                                  textField: {
                                    onBlur: handleBlur,
                                    helperText:
                                      touched.dateRanges?.[index]?.endDate &&
                                      errors.dateRanges?.[index]?.endDate,
                                    error: Boolean(
                                      touched.dateRanges?.[index]?.endDate &&
                                        errors.dateRanges?.[index]?.endDate
                                    )
                                  }
                                }}
                                inputFormat="dd/MM/yyyy"
                              />

                              <Box display="flex" alignItems="center">
                                <IconButton
                                  onClick={() => {
                                    if (values?.dateRanges?.length < credit) {
                                      arrayHelpers.push({
                                        campaign_name: null,
                                        startDate: null,
                                        endDate: null
                                      });
                                    } else {
                                      toast.error(
                                        <>
                                          <Typography>
                                            Credit has been limited. Click{' '}
                                            <Link to="/plans">here</Link> to add
                                            more.
                                          </Typography>
                                        </>
                                      );
                                    }
                                  }}
                                  disableRipple
                                  sx={{ margin: 'none' }}
                                >
                                  <AddIcon />
                                </IconButton>

                                {index > 0 && (
                                  <IconButton
                                    disableRipple
                                    sx={{ margin: 'none' }}
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                              </Box>
                            </Stack>
                          ))}
                        </Box>
                      </Stack>
                    )}
                  />
                  <input
                    type="file"
                    accept="text/csv, .csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      const selectedFile = event.currentTarget.files[0];
                      setFieldValue('file', selectedFile);
                      const fileName = selectedFile
                        ? selectedFile.name
                        : 'Select file';
                      document.getElementById('file-input-label').innerText =
                        fileName;
                    }}
                    style={{
                      display: 'none'
                    }}
                    id="file-input"
                  />
                  <label htmlFor="file-input">
                    <Button
                      component="span"
                      variant="outlined"
                      sx={{
                        width: '90%',
                        mt: 10,
                        height: '150px',
                        textTransform: 'none',
                        border: '1px dashed black'
                      }}
                    >
                      <span id="file-input-label">
                        Please, add additional data in .CSV/ .EXCEL formats
                      </span>
                    </Button>
                  </label>

                  {touched.file && (
                    <Typography color="error" style={{ mt: 1 }}>
                      {errors.file}
                    </Typography>
                  )}

                  <Stack
                    direction="row"
                    justifyContent="center"
                    width="100%"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                      startIcon={
                        loading ? <CircularProgress size="1rem" /> : null
                      }
                      sx={{ width: '50%', mt: 3, py: 1 }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Box>
              </Container>
            </form>
          )}
        </Formik>
      </Stack>
    </Layout>
  );
};

export default AddData;
