import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: 'https://horiz-on.ai',
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Token ${token}`;
    }

    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }

    console.log(config);

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const loginApi = async (payload) => {
  let result;
  try {
    result = await axiosInstance.post('/backend/api/auth/login/', payload);
  } catch (e) {
    result = e;
  }
  return result;
};

export const signUpApi = async (payload) => {
  let result;
  try {
    result = await axiosInstance.post('/backend/api/auth/signup/', payload);
  } catch (e) {
    result = e;
  }
  return result;
};

export const logoutApi = async () => {
  let result;
  try {
    result = await axiosInstance.post('/backend/api/auth/logout/');
  } catch (e) {
    result = e;
  }
  return result;
};

export const aboutUsApi = async () => {
  let result;
  try {
    result = await axiosInstance.get('/backend/api/about-us/');
  } catch (e) {
    result = e;
  }
  return result;
};

export const addCollectionApi = async (token, payload) => {
  let result;
  try {
    result = await axiosInstance.post(
      '/backend/api/create-collection/',
      payload
    );
  } catch (e) {
    result = e;
  }
  return result;
};

export const getCollectionList = async (limit, offset) => {
  let result;
  try {
    result = await axiosInstance.get(
      `/backend/api/collection-list/?pageSize=${limit}&page=${offset}`
    );
  } catch (e) {
    result = e;
  }
  return result;
};

export const getStaticstics = async (id) => {
  let result;
  try {
    result = await axiosInstance.get(`/backend/api/campaign-statistics/${id}`);
  } catch (e) {
    result = e;
  }
  return result;
};

export const getSingleCollection = async (payload) => {
  let result;
  try {
    result = await axiosInstance.get(
      `/backend/api/collection-details/${payload}/`
    );
  } catch (e) {
    result = e;
  }
  return result;
};

export const getPaymentGatWayInfo = async () => {
  let result;
  try {
    result = await axiosInstance.get(`/backend/api/plan-list/`);
  } catch (e) {
    result = e;
  }
  return result;
};

export const createStripePayment = async (payload) => {
  let result;
  try {
    result = await axiosInstance.post(
      `/backend/api/create-stripe-session/`,
      payload
    );
  } catch (e) {
    result = e;
  }
  return result;
};

export const verifyStripePayment = async (payload) => {
  let result;
  try {
    result = await axiosInstance.post(
      `/backend/api/verify-stripe-payment/`,
      payload
    );
  } catch (e) {
    result = e;
  }
  return result;
};

export const createPaypalPayment = async (payload) => {
  let result;
  try {
    result = await axiosInstance.post(
      `/backend/api/create-paypal-session/`,
      payload
    );
  } catch (e) {
    result = e;
  }
  return result;
};

export const verifyPaypalPayment = async (payload) => {
  let result;
  try {
    result = await axiosInstance.post(
      `/backend/api/verify-paypal-payment/`,
      payload
    );
  } catch (e) {
    result = e;
  }
  return result;
};
