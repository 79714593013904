import React from 'react';
import BG_IMAGE from '../../assets/images/Background-image-3.png';
import { Box } from '@mui/material';
import Header from '../Header';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {
  const location = useLocation();

  return (
    <div className="main-container">
      <img className="main-bg-img" src={BG_IMAGE} alt="" />
      <Box
        sx={{
          maxHeight: '100vh',
          overflow: 'auto'
        }}
      >
        {location.pathname !== '/login' &&
          location.pathname !== '/register' && <Header />}

        {children}
      </Box>
    </div>
  );
};

export default Layout;
