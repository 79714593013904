import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LOGO from 'src/assets/images/logo.png';
import {
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  styled,
  Box,
  useMediaQuery,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { logoutApi } from 'src/api';
import { useAuth } from 'src/utils/AuthWrapper';
import { toast } from 'react-toastify';
import MenuIcon from '@mui/icons-material/Menu';

const LinkComponent = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const Header = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isLaptopScreen = useMediaQuery('(max-width:1200px)');

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const onLogOut = async () => {
    await logoutApi()
      .then((res) => {
        if (res?.response) {
          if (res.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('credit');
            logout();
            toast.error("You've been logged out. Please log in again.");
            return;
          }
        }
        if (res?.data?.status) {
          localStorage.clear();
          logout();
          navigate('/login');
          toast.success(res?.data?.msg);
        } else {
          toast.success(res?.data);
        }
      })
      .catch((e) => console.log('==>', e));
  };

  return (
    <>
      <AppBar
        position="relative"
        sx={{
          py: 3,
          px: 6,
          color: '#000',
          backgroundColor: '#CEE6F9'
        }}
      >
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            <LinkComponent to="/add-data">
              <img
                src={LOGO}
                alt="logo"
                style={{
                  height: 40,
                  maxWidth: '100%',
                  width: 'auto'
                }}
              />
            </LinkComponent>
          </Typography>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              width: isLaptopScreen ? '60%' : '40%',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            <LinkComponent to="/about">
              <Typography mr={2} variant="h6" textTransform="uppercase">
                About Us
              </Typography>
            </LinkComponent>
            <LinkComponent to="/add-data">
              <Typography mr={2} variant="h6" textTransform="uppercase">
                Add Data
              </Typography>
            </LinkComponent>
            <LinkComponent to="/history">
              <Typography mr={2} variant="h6" textTransform="uppercase">
                History
              </Typography>
            </LinkComponent>
            <LinkComponent to="/plans">
              <Typography mr={2} variant="h6" textTransform="uppercase">
                Plans
              </Typography>
            </LinkComponent>
            <Button onClick={onLogOut}>Logout</Button>
          </Box>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ display: { md: 'none' } }}
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor="top" open={isDrawerOpen} onClose={toggleDrawer}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          my={2}
          mx={2}
        >
          <LinkComponent to="/add-data">
            <img
              src={LOGO}
              alt="logo"
              style={{
                height: 40,
                maxWidth: '100%',
                width: 'auto'
              }}
            />
          </LinkComponent>
          <IconButton onClick={() => setIsDrawerOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <List>
          {['About', 'Add Data', 'History', 'Plans'].map((text) => (
            <ListItem
              button
              key={text}
              component={Link}
              to={`/${text.toLowerCase().replace(/\s/g, '-')}`}
            >
              <ListItemText primary={text} />
            </ListItem>
          ))}
          <ListItem>
            <Button onClick={onLogOut}>Logout</Button>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default Header;
