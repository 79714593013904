import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, Paper, Typography, Button } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { getSingleCollection, getStaticstics } from 'src/api';
import Layout from 'src/components/Layout';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

const CampaignsList = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (id) {
      getSingleCollection(id)
        .then((res) => {
          if (res?.data?.status) {
            setData(res?.data?.data.campaigns);
            setLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [id]);

  const downloadCSV = async (campaignId) => {
    const stats = await getStaticstics(campaignId);
    const csv = Papa.unparse(stats?.data?.data?.campaign_statistics || []);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `campaign_${campaignId}_statistics.csv`);
  };

  const downloadXLSX = async (campaignId) => {
    const stats = await getStaticstics(campaignId);
    const worksheet = XLSX.utils.json_to_sheet(stats?.data?.data?.campaign_statistics || []);
    const workbook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    };
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });
    saveAs(blob, `campaign_${campaignId}_statistics.xlsx`);
  };


  const columns = [
    {
      field: 'campaign_name',
      headerName: 'Campaign Name',
      flex: 1,
      renderHeader: (params) => (
        <Typography variant="h5">{params.colDef.headerName}</Typography>
      ),
      renderCell: (params) => {
        return (
          <Link
            style={{ textDecoration: 'none' }}
            to={`/history/${id}/${params?.row?.campaign_id}`}
          >
            <Typography color="#000" variant="body1">
              {params.row.campaign_name}
            </Typography>
          </Link>
        );
      }
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      flex: 1,
      renderHeader: (params) => (
        <Typography variant="h5">{params.colDef.headerName}</Typography>
      ),
      renderCell: (params) => {
        return (
          <Typography variant="body1">
            {moment.utc(params.row.start_date).format('lll')}
          </Typography>
        );
      }
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      flex: 1,
      renderHeader: (params) => (
        <Typography variant="h5">{params.colDef.headerName}</Typography>
      ),
      renderCell: (params) => {
        return (
          <Typography variant="body1">
            {moment.utc(params.row.end_date).format('lll')}
          </Typography>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderHeader: (params) => (
        <Typography variant="h5">{params.colDef.headerName}</Typography>
      ),
      renderCell: (params) => {
        const campaignId = params?.row?.campaign_id;
        return (
          <div>
            <Button onClick={() => downloadCSV(campaignId)}>Download CSV</Button>
            <Button onClick={() => downloadXLSX(campaignId)}>Download XLSX</Button>
          </div>
        );
      }
    }
  ];

  return (
    <Layout>
      <Typography textAlign="center" my={3} variant="h3">
        Campaigns
      </Typography>
      <Container>
        {loading ? (
          <CircularProgress sx={{ display: 'flex', margin: '0 auto', mt: 2 }} />
        ) : (
          <Paper sx={{ backgroundColor: '#ffffffa1' }}>
            <>
              {data?.length >= 1 ? (
                <DataGrid
                  rows={data?.map((user, index) => ({
                    ...user,
                    id: index.toString()
                  }))}
                  columns={columns}
                  hideFooter
                  disableRowSelectionOnClick
                />
              ) : (
                <Typography textAlign="center" variant="h4" p={4}>
                  No data found
                </Typography>
              )}
            </>
          </Paper>
        )}
      </Container>
    </Layout>
  );
};

export default CampaignsList;
