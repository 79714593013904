import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { Container, Paper, Typography } from '@mui/material';
import Layout from 'src/components/Layout';
import { getStaticstics } from 'src/api';

const CampaignStatistics = () => {
  const { campaignId } = useParams();
  console.log('==>', campaignId);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (campaignId) {
      fetchStatistics();
    }
  }, [campaignId]);

  const fetchStatistics = async () => {
    const statistics = await getStaticstics(campaignId);
    setData(statistics?.data?.data?.campaign_statistics || []);
  };

  const transformDataForChart = (
    data,
    seriesName,
    yKey,
    lowerKey,
    upperKey,
    dashLineKey,
    lineColor,
    dashLineColor,
    rangeColor
  ) => {
    const series = [
      {
        name: seriesName,
        type: 'line',
        data: data?.map((point) => ({
          x: new Date(point?.date).getTime(),
          y: point[yKey]
        })),
        color: lineColor
      },
      {
        name:
          dashLineKey === 'blue_dashed_value' ? 'Target without campaign' : '',
        type: 'line',
        data: data?.map((point) => ({
          x: new Date(point?.date)?.getTime(),
          y: point[dashLineKey]
        })),
        color: dashLineColor
      },
      {
        name:
          dashLineKey === 'cumulatative_point_effects'
            ? 'Cumulative Error'
            : 'Prediction Error',
        type: 'rangeArea',
        data: data?.map((point) => ({
          x: new Date(point?.date)?.getTime(),
          y: [point[lowerKey], point[upperKey]]
        })),
        fillTo: 'end',
        fillOpacity: 0.3,
        color: rangeColor,
        marker: {
          colors: [rangeColor]
        }
      }
    ];

    return {
      series,
      options: {
        chart: {
          height: 350,
          type: 'rangeArea',
          animations: {
            speed: 500
          }
        },

        grid: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          colors: [rangeColor],
          opacity: [1, 3]
        },
        stroke: {
          colors: [lineColor, dashLineColor],
          curve: 'smooth',
          width: [2, 2, 0]
        },
        legend: {
          show: true,
          markers: {
            fillColors: [lineColor, dashLineColor, rangeColor]
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 5
          }
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value?.toFixed(3);
            }
          }
        }
      }
    };
  };

  const chartAData = transformDataForChart(
    data,
    'Target',
    'target',
    'lower_a',
    'upper_a',
    'blue_dashed_value',
    '#b33b72',
    '#33b2df',
    '#87CEFA'
  );

  const chartBData = transformDataForChart(
    data,
    'Point Effects',
    'point_effects',
    'lower_b',
    'upper_b',
    null,
    '#33b2df',
    null,
    '#87CEFA'
  );

  const chartCData = transformDataForChart(
    data,
    'Cumulative Point Effects',
    'cumulatative_point_effects',
    'lower_c',
    'upper_c',
    null,
    '#33b2df',
    null,
    '#87CEFA'
  );

  return (
    <Layout>
      <Typography variant="h3" textAlign="center" my={3}>
        Campaign Statistics
      </Typography>
      {data.length > 0 && (
        <Container>
          <Paper sx={{ backgroundColor: '#ffffffa1' }}>
            <ReactApexChart
              options={chartAData.options}
              series={chartAData.series}
              type="rangeArea"
              height={350}
            />
          </Paper>
          <Paper sx={{ mt: 2, backgroundColor: '#ffffffa1' }}>
            <ReactApexChart
              options={chartBData.options}
              series={chartBData.series}
              type="rangeArea"
              height={350}
            />
          </Paper>
          <Paper sx={{ mt: 2, backgroundColor: '#ffffffa1' }}>
            <ReactApexChart
              options={chartCData.options}
              series={chartCData.series}
              type="rangeArea"
              height={350}
            />
          </Paper>
        </Container>
      )}
    </Layout>
  );
};

export default CampaignStatistics;
