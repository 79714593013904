import { createContext, useContext, useEffect, useState } from 'react';
import { axiosInstance } from 'src/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setLoggedIn(true);
      axiosInstance.defaults.headers.common.Authorization = `Token ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common.Authorization;
      setLoggedIn(false);
    }
  }, []);

  const login = () => {
    setLoggedIn(true);
  };

  const logout = () => {
    delete axiosInstance.defaults.headers.common.Authorization;
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
