import React from 'react';
import { Box, Card, Link, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Failed = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Card
        sx={{
          mt: 5,
          height: 400,
          width: 400,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            backgroundColor: '#FF7F7F',
            height: 120,
            width: 120,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 2,
            borderRadius: '50%'
          }}
        >
          <CloseIcon sx={{ fontSize: 70, color: 'red' }} />
        </Box>
        <Typography
          textAlign="center"
          color="red"
          my={3}
          fontSize={32}
          fontWeight={700}
        >
          Failed
        </Typography>
        <Typography textAlign="center">
          We received your purchase request. <br />
          we'll be in touch shortly!
        </Typography>
        <Link to="/add-data">
          <Typography>Go to Home Page</Typography>
        </Link>
      </Card>
    </Box>
  );
};

export default Failed;
