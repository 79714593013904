import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import BG_IMAGE from '../../assets/images/Background-image-3.png';

const NotFound = () => {
  return (
    <div className="main-container">
      <img className="main-bg-img" src={BG_IMAGE} alt="" />
      <Box
        sx={{
          maxHeight: '100vh',
          overflow: 'auto'
        }}
      >
        <Stack
          direction="row"
          height="70vh"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          spacing={4}
        >
          <Typography fontSize={100}>404</Typography>
          <Typography fontSize={70}>Page not found</Typography>
          <Link to="/dashboard">
            <Typography variant="h6" textTransform="uppercase">
              Go to Home page
            </Typography>
          </Link>
        </Stack>
      </Box>
    </div>
  );
};

export default NotFound;
