import React, { useEffect, useState } from 'react';
import { Box, Card, CircularProgress, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { verifyPaypalPayment, verifyStripePayment } from 'src/api';
import { useAuth } from 'src/utils/AuthWrapper';
import { toast } from 'react-toastify';

const Success = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('session_id');
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const { logout } = useAuth();

  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(false);
    if (token) {
      let obj = {
        order_id: token
      };
      verifyPaypalPayment(obj)
        .then((res) => {
          if (res?.response) {
            if (res.response.status === 401) {
              localStorage.removeItem('token');
              localStorage.removeItem('userId');
              localStorage.removeItem('credit');
              logout();
              navigate('/login');
              toast.error("You've been logged out. Please log in again.");
              return;
            }
          }
          if (res?.data?.status) {
            const newCredit = parseInt(res.data.data.available_credit) || 0;
            localStorage.setItem('credit', newCredit);
            setLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [token]);

  useEffect(() => {
    setLoading(true);
    if (id) {
      let obj = {
        session_id: id
      };
      verifyStripePayment(obj)
        .then((res) => {
          if (res?.response) {
            if (res.response.status === 401) {
              localStorage.removeItem('token');
              localStorage.removeItem('userId');
              localStorage.removeItem('credit');
              logout();
              navigate('/login');
              toast.error("You've been logged out. Please log in again.");
              return;
            }
          }
          if (res?.data?.data) {
            const newCredit = parseInt(res.data.data.available_credit) || 0;
            localStorage.setItem('credit', newCredit);
            setLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [id]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {loading ? (
        <CircularProgress sx={{ display: 'flex', margin: '0 auto', mt: 2 }} />
      ) : (
        <Card
          sx={{
            mt: 5,
            height: 400,
            width: 400,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              backgroundColor: 'lightgreen',
              height: 120,
              width: 120,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 2,
              borderRadius: '50%'
            }}
          >
            <CheckIcon sx={{ fontSize: 70, color: 'green' }} />
          </Box>
          <Typography
            textAlign="center"
            color="green"
            my={3}
            fontSize={32}
            fontWeight={700}
          >
            Success
          </Typography>
          <Typography textAlign="center">
            We received your purchase request. <br />
            we'll be in touch shortly!
          </Typography>
          <Link to="/add-data">
            <Typography>Go to Home Page</Typography>
          </Link>
        </Card>
      )}
    </Box>
  );
};

export default Success;
