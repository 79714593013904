import React, { useEffect, useState } from 'react';
import Layout from 'src/components/Layout';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { aboutUsApi } from 'src/api';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/utils/AuthWrapper';
import { toast } from 'react-toastify';

const About = () => {
  const [data, setData] = useState();
  const token = localStorage.getItem('token');
  const isLaptopScreen = useMediaQuery('(max-width: 1100px)');
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchAboutUs();
  }, []);

  const fetchAboutUs = () => {
    aboutUsApi(token)
      .then((res) => {
        if (res?.response) {
          if (res.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('credit');
            logout();
            navigate('/login');
            toast.error("You've been logged out. Please log in again.");
            return;
          }
        }
        if (res?.data?.status) {
          setData(res?.data?.data);
        }
      })
      .catch((e) => console.log('eeee', e));
  };

  return (
    <Layout>
      <Stack
        sx={{
          p: 2,
          margin: 'auto',
          display: 'flex',
          pt: 12,
          flexDirection: 'column',
          alignItems: 'center',
          width: isLaptopScreen ? '70%' : '40%'
        }}
        spacing={4}
      >
        <Typography variant="h2" textTransform="uppercase">
          About us
        </Typography>
        <Typography fontSize={16} color={'rgb(10, 50, 80)'}>
          <div dangerouslySetInnerHTML={{ __html: data?.about_us }} />
        </Typography>
      </Stack>
    </Layout>
  );
};

export default About;
