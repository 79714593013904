import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import Layout from 'src/components/Layout';
import CloseIcon from '@mui/icons-material/Close';
import {
  createPaypalPayment,
  createStripePayment,
  getPaymentGatWayInfo
} from 'src/api';
import StripeLogo from 'src/assets/images/StripeLogo.png';
import PaypalLogo from 'src/assets/images/paypal.png';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/utils/AuthWrapper';
import { toast } from 'react-toastify';

const Plans = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState([]);
  const [planId, setPlanId] = useState();
  const [isSubmitting, setIsSubmitting] = useState();
  const navigate = useNavigate();
  const { logout } = useAuth();
  console.log(isSubmitting);

  const handleDialogOpen = (id) => {
    setPlanId(id);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchPaymentInfo();
  }, []);

  const fetchPaymentInfo = () => {
    getPaymentGatWayInfo()
      .then((res) => {
        if (res?.response) {
          if (res.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('credit');
            logout();
            navigate('/login');
            toast.error("You've been logged out. Please log in again.");
            return;
          }
        }
        if (res) {
          console.log(res.data.data);
          setData(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const handlePaypal = () => {
    setIsSubmitting('paypal');
    let obj = {
      plan_id: planId
    };
    createPaypalPayment(obj)
      .then((res) => {
        if (res.data.status) {
          window.location.href = res.data.data.url;
          setIsSubmitting();
        }
      })
      .catch((e) => console.log(e));
  };

  const handleStripe = () => {
    setIsSubmitting('stripe');
    let obj = {
      plan_id: planId
    };
    createStripePayment(obj)
      .then((res) => {
        if (res.data.status) {
          window.location.href = res.data.data.url;
          setIsSubmitting();
        }
      })
      .catch((e) => console.log(e));
  };

  const credit = localStorage.getItem('credit');

  return (
    <Layout>
      <Container>
        <Paper sx={{ mt: 5, backgroundColor: '#ffffffa1' }}>
          <Stack
            sx={{
              p: 2,
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
            spacing={4}
          >
            <Typography variant="h2" textTransform="uppercase">
              Pricing Plans
            </Typography>
            <Typography textTransform="uppercase" fontSize={18}>
              Credit:{credit}
            </Typography>
          </Stack>
        </Paper>
        <Grid mt={0.5} container spacing={4} justifyContent="center">
          {data.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  backgroundColor: '#ffffffa1',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  padding: 2
                }}
              >
                <CardContent>
                  <Typography variant="h5" mt={2} fontSize="1.5rem">
                    {item.plan_name}
                  </Typography>
                  <Typography variant="body1" mt={2} mb={2}>
                    {item.plan_description}
                  </Typography>
                  <Typography variant="h6" color="textSecondary" mt={3}>
                    CREDIT {item.credit}
                  </Typography>
                  <Typography variant="h3" color="primary">
                    ${item.amount} USD
                  </Typography>
                </CardContent>
                <Button
                  variant="contained"
                  color="primary"
                  mt={5}
                  sx={{
                    width: 'auto',
                    my: 2
                  }}
                  onClick={() => handleDialogOpen(item.plan_id)}
                >
                  BUY NOW
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
        {/* Dialog component for payment cancellation */}

        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          maxWidth="xs"
          fullWidth
          keepMounted
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box p={3} sx={{ boxShadow: '0 12px 12px rgba(0, 0, 0, 0.1)' }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Payment Methods</Typography>
              <IconButton onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider />
            <Stack mt={2} mb={2} direction="row" spacing={2}>
              <Card
                onClick={handleStripe}
                sx={{
                  height: 120,
                  cursor: 'pointer',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {isSubmitting === 'stripe' ? (
                  <CircularProgress
                    sx={{ display: 'flex', margin: '0 auto' }}
                  />
                ) : (
                  <img width="90" src={StripeLogo} alt="" />
                )}
              </Card>
              <Card
                onClick={handlePaypal}
                sx={{
                  height: 120,
                  cursor: 'pointer',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {isSubmitting === 'paypal' ? (
                  <CircularProgress
                    sx={{ display: 'flex', margin: '0 auto' }}
                  />
                ) : (
                  <img width="125" src={PaypalLogo} alt="" />
                )}
              </Card>
            </Stack>
          </Box>
        </Dialog>
      </Container>
    </Layout>
  );
};

export default Plans;
