import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import LOGO from 'src/assets/images/logo.png';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  IconButton
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import { aboutUsApi, axiosInstance, loginApi } from 'src/api';
import { useAuth } from 'src/utils/AuthWrapper';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Login = () => {
  const isSmScreen = useMediaQuery('(max-width:768px)');
  const isTabletScreen = useMediaQuery('(max-width:1024px)');
  // const isLaptopScreen = useMediaQuery('(max-width: 1100px)');
  const navigate = useNavigate();
  const { login } = useAuth();
  const [message, setMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [data, setData] = useState();
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchAboutUs();
  }, []);

  const fetchAboutUs = () => {
    aboutUsApi(token)
      .then((res) => {
        if (res?.data?.status) {
          setData(res?.data?.data);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <Layout>
      <Grid container spacing={isSmScreen ? 2 : 4}>
        <Grid item xs={12} md={7}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            height={isSmScreen ? 'auto' : '100vh'}
            justifyContent="center"
            px={isSmScreen ? 0 : 2}
            width={isTabletScreen ? '80%' : '60%'}
            sx={{ margin: '0 auto', py: 4 }}
          >
            <Box display="flex">
              <img
                src={LOGO}
                alt="logo"
                style={{
                  height: 'auto',
                  maxWidth: '100%',
                  width: isTabletScreen ? 250 : 300
                }}
              />
            </Box>
            <Stack
              mt={isTabletScreen ? 4 : 10}
              sx={{
                width: '100%',
                display: 'flex'
              }}
              spacing={4}
            >
              <Typography variant="h3" textTransform="uppercase">
                About us
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: data?.about_us }} />
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={isSmScreen ? 12 : 0.8}>
          <div
            style={{
              width: isSmScreen ? '100%' : '2px',
              height: isSmScreen ? '2px' : '100%',
              borderBottom: isSmScreen ? '2px dashed' : 'none',
              borderLeft: isSmScreen ? 'none' : '2px dashed',
              margin: isSmScreen ? '8px 0' : '0 16px'
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={isSmScreen ? 12 : 4}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              width: '100%',
              mx: isTabletScreen && 2,
              background: 'transparent',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              py: isSmScreen && 4
            }}
          >
            <Typography variant="h3" textTransform="uppercase">
              Log in
            </Typography>
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().trim().required('Required'),
                password: Yup.string().trim().required('Required')
              })}
              onSubmit={async (
                _values,
                { resetForm, setStatus, setSubmitting, setErrors }
              ) => {
                await loginApi(_values)
                  .then((res) => {
                    if (res?.data?.status) {
                      localStorage.setItem('token', res?.data?.data?.token);
                      localStorage.setItem('userId', res?.data?.data?.user_id);
                      localStorage.setItem(
                        'credit',
                        res?.data?.data?.available_credits
                      );
                      axiosInstance.defaults.headers.common.Authorization = `Token ${res?.data?.data?.token}`;
                      login();
                      navigate('/add-data');
                      toast.success(res?.data?.msg);
                      resetForm();
                      setStatus({ success: true });
                      setSubmitting(false);
                    } else {
                      setMessage(res?.response?.data?.error?.msg);
                    }
                  })
                  .catch((err) => {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    name="email"
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    label={'Email'}
                    placeholder={'Email'}
                    sx={{ mt: 2 }}
                    autoComplete="off"
                  />
                  <TextField
                    fullWidth
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    label={'Password'}
                    placeholder={'Password'}
                    autoComplete="off"
                    sx={{ mt: 2, borderRadius: 50 }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          edge="end"
                          onClick={handleTogglePasswordVisibility}
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      )
                    }}
                  />
                  {message && <Typography color="error">{message}</Typography>}

                  <Stack
                    direction="row"
                    justifyContent="center"
                    width="100%"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                      startIcon={
                        isSubmitting ? <CircularProgress size="1rem" /> : null
                      }
                      sx={{ width: '100%', mt: 3, py: 1 }}
                    >
                      Sign in
                    </Button>
                    <Button
                      sx={{ width: '40%', py: 1, mt: 5 }}
                      variant="contained"
                      onClick={() => navigate('/register')}
                    >
                      Register
                    </Button>
                  </Stack>
                </form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Login;
